import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#253c99', // #E53935
				secondary: '#13a689', // #FFCDD2
				lightgray: colors.grey.lighten5,
				// success: '#13a689',
				error: '#ff3334',
				errorLight: '#eeb4b3',
				green: '#0f8145',
			},
		},
	},
});
