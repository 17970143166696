<template>
    <div>
		<v-dialog
			v-model="dialog"
			persistent
			fullscreen
			transition="slide-x-reverse-transition"
		>
            <v-card flat>
                <!-- title -->
                <v-toolbar dark flat color="primary" class="text-uppercase">
                    <v-spacer/>
                    {{title}}
                    <v-spacer/>
                    <v-toolbar-items text samll>
                        <v-btn small text @click="dialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-form @submit.prevent="submit" ref="form">
                    <!-- radio buttons -->
                    <v-radio-group
                      row
                      hide-details
                      v-model="dirType"
                      v-if="id === null"
                      @change="reset"
                    >
                        <v-radio label="Doctors" :value="1" class="mx-auto" color="success"/>
                        <v-radio label="Pharmacies" :value="2" class="mx-auto" color="success"/>
                        <v-radio label="Centers" :value="0" class="mx-auto" color="success"/>
                    </v-radio-group>

                    <!-- card content -->
                    <v-card-text class="pb-0">
                        <v-container>
                            <v-row dense>
                                <!-- date -->
                                <v-col md="6" cols="12">
									<v-menu
										v-model="visitDateMenu"
										:close-on-content-click="false"
										offset-y
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="visitDate"
												label="Date"
												hide-details="auto"
												dense
												:disabled="Boolean(id)"
												readonly
												:rules="rules.date"
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="visitDate"
											:disabled="Boolean(id)"
											:min="moment().subtract(1, 'day').format('YYYY-MM-DD')"
											:max="moment().format('YYYY-MM-DD')"
											@input="visitDateMenu = false"
										></v-date-picker>
									</v-menu>
                                </v-col>

								<!-- region -->
                                <v-col md="6" cols="12">
									<v-autocomplete
										v-model="regionId"
										:items="regions"
										item-text="name"
										item-value="id"
										label="Region"
										@change="fetchEntities"
										:rules="rules.required"
										hide-details="auto"
									></v-autocomplete>
                                </v-col>

                                <!-- entity -->
                                <v-col md="6" cols="12">
									<v-autocomplete
										v-model="entityId"
										:items="entities"
										item-text="name"
										item-value="id"
										label="Customer Name"
										:loading="EntitiesLoading"
										:disabled="EntitiesLoading || regionId === null"
									></v-autocomplete>
                                </v-col>

								<!-- persons -->
								<v-col v-if="dirType === 0 || dirType === 2">
									<div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2">mdi-account</v-icon>
                                            <span class="text-body-1">Persons</span>
                                        </div>

                                        <v-btn
											text
											@click="personsNames.push({
												id: personsNames.length,
												name: null
											})"
                                        >
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
									
									<v-card v-if="personsNames.length > 0" class="my-2">
                                        <v-card-text>
                                            <v-row
												v-for="(person, index) in personsNames"
												:key="person.id"
												dense
												class="align-center"
                                            >
                                                <!-- sample -->
                                                <v-col cols="10">
													<v-text-field
														v-model="person.name"
														placeholder="name"
														dense
														hide-details
														:rules="rules.required"
													></v-text-field>
                                                </v-col>

                                                <v-col cols="2" class="pa-0">
													<v-btn
														text
														small
														@click="
															personsNames.forEach((c, i) => {
																if (i > index) personsNames[i].id--;
															})
															personsNames.splice(index, 1);
														"
													>
                                                        <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
								</v-col>

                                <!-- samples -->
                                <v-col cols="12">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2">mdi-pill</v-icon>
                                            <span class="text-body-1">Samples</span>
                                        </div>

                                        <v-btn text @click="addSample">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    
                                    <!-- sample card -->
                                    <v-card v-if="samples.length > 0" class="my-2">
                                        <v-card-text>
                                            <v-row dense v-for="sample in samples" :key="sample.id" class="align-center">
                                                <!-- sample -->
                                                <v-col cols="7">
													<v-autocomplete
														v-model="sample.data.materialId"
														:items="materials"
														:item-text="getFullMaterialName"
														item-value="id"
														placeholder="sample"
														dense
														:rules="rules.required"
														hide-details
													></v-autocomplete>
                                                </v-col>

                                                <!-- amount -->
                                                <!-- <v-col :cols="samples.length > 1 ? 3 : 5"> -->
                                                <v-col :cols="3">
													<v-text-field
														v-model.number="sample.data.amount"
														placeholder="amount"
														type="number"
														min="0"
														dense
														hide-spin-buttons
														hide-details
														:rules="[...rules.number, val => val > 0]"
													></v-text-field>
                                                </v-col>

                                                <!-- remove button -->
                                                <!-- <v-col v-if="samples.length > 1" cols="2" class="pa-0"> -->
                                                <v-col cols="2" class="pa-0">
													<v-btn text small @click="removeSample(sample.id)">
                                                        <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <!-- unavailable materialIds -->
                                <v-col md="6" cols="12" v-if="dirType === 2">
									<v-autocomplete
										v-model="unavailableMaterialIds"
										:items="allMaterials"
										:item-text="getFullMaterialName"
										item-value="id"
										label="Unavailable Products"
										dense
										multiple
										hide-details="auto"
										prepend-icon="mdi-close"
										class="mt-1"
									>
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" class="me-1">{{ getFullMaterialName(item) }}</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ unavailableMaterialIds.length - 1 }} others)
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <!-- competitors -->
                                <v-col md="6" cols="12" v-if="dirType === 2">
                                    <div class="d-flex flex-grow-1 justify-space-between align-center">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-2">mdi-trending-up</v-icon>
                                            <span class="text-body-1">Competitors</span>
                                        </div>

                                        <v-btn text @click="addCompetitor">
                                            <v-icon color="grey darken-3">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </div>

                                    <!-- Competitor card -->
                                    <v-card v-if="competitors.length > 0" class="my-2">
                                        <v-card-text>
                                            <v-row
												v-for="competitor in competitors"
												:key="competitor.id"
												dense
												class="align-center"
                                            >
                                                <!-- competitor -->
                                                <v-col cols="10">
													<v-text-field
														v-model="competitor.value"
														placeholder="Competitor"
														dense
														hide-details
														required
													></v-text-field>
                                                </v-col>

                                                <!-- remove button -->
                                                <v-col cols="2" class="pa-0">
                                                    <v-btn
                                                    text
                                                    small
                                                    @click="removeCompetitor(competitor.id)"
                                                    >
                                                        <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

								<!-- coreMessage -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="coreMessage"
										label="Core message"
										prepend-icon="mdi-note-outline"
										hide-details
									></v-text-field>
                                </v-col>

								<!-- durationInMinutes -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model.number="durationInMinutes"
										label="Duration (in minutes)"
										type="number"
										hide-spin-buttons
										suffix="minutes"
										min="0"
										prepend-icon="mdi-clock-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- other materials -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="otherSamples"
										label="Other Materials"
										prepend-icon="mdi-plus-minus-box"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- visitNotes -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="visitNotes"
										label="Visit Notes"
										prepend-icon="mdi-note-multiple-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- objection -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="objection"
										label="Objection"
										prepend-icon="mdi-alert-circle-outline"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- nextCall -->
                                <v-col md="6" cols="12">
									<v-text-field
										v-model="nextCall"
										label="Next Call"
										prepend-icon="mdi-check"
										hide-details
									></v-text-field>
                                </v-col>

                                <!-- center notes -->
                                <v-col md="6" cols="12" v-if="dirType === 2">
									<v-text-field
										v-model="centerNotes"
										label="Notes"
										prepend-icon="mdi-note-multiple-outline"
										hide-details
									></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text type="submit" :loading="loading" :disabled="loading">
                            Save
                        </v-btn>
                        <v-btn color="grey darken-1" text @click="dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
export default {
	data() {
		return {
			// helpers
			id: null,
			dialog: false,

			title: null,

			loading: false,
			EntitiesLoading: false,
			rules,
            
			sampleCounter: 1,
			competitorCounter: 0,

			visitDateMenu: false,
			moment,

			// data
			dirType: 0,
			visitDate: null,
			regionId: null,
			entityId: null,
			personsNames: [],
			// samples: [{ id: 1, data: { materialId: null, amount: null } }],
			samples: [],
			coreMessage: null,
			durationInMinutes: null,
			otherSamples: null,
			visitNotes: null,
			objection: null,
			nextCall: null,
			unavailableMaterialIds: [],
			competitors: [],
			centerNotes: null
		}
	},

	methods: {
		reset() {
			if (this.$refs.form) this.$refs.form.resetValidation();

			this.id = null;
			this.sampleCounter = 1;
			this.competitorCounter = null;

			this.regionId = null;
			this.entityId = null;
			// this.samples = [{ id: 1, data: { materialId: null, amount: null } }];
			this.personsNames = [];
			this.samples = [];
			this.coreMessage = null;
			this.durationInMinutes = null;
			this.otherSamples = null;
			this.visitNotes = null;
			this.objection = null;
			this.nextCall = null;
			this.unavailableMaterialIds = [];
			this.competitors = [];
			this.centerNotes = null;
		},

		// samples
		addSample() {
			this.samples.push({
				id: ++this.sampleCounter,
				data: {
					materialId: null,
					amount: null
				}
			})
		},

		removeSample(id) {
			const index = this.samples.findIndex(c => c.id === id);
			this.samples.splice(index, 1);
			this.samples.forEach(sample => {
				if (sample.id > index) {
					sample.id--;
				}
			})
			this.sampleCounter--;
		},
        
		// competitor
		addCompetitor() {
			this.competitors.push({
				id: this.competitorCounter++,
				value: null
			})
		},

		removeCompetitor(index) {
			this.competitors.splice(index, 1);
			this.competitors.forEach(competitor => {
				if (competitor.id > index) {
					competitor.id--;
				}
			})
			this.competitorCounter--;
		},

		// find data
		getFullMaterialName(material) {
			const details = [
				this.getMaterialFormById(material.materialFormId).name,
				material.dose,
				material.strength
			];

			return `${material.name} (${details.filter(c => c != null).join(' ')})`;
		},

		fetchEntities() {
			this.EntitiesLoading = true;

			this.$store.dispatch('entities/fetchAll', { type: this.dirType, regionId: this.regionId })
				.finally(() => {
					this.EntitiesLoading = false;
				})
		},

		// dialog functions
		addVisit(type = 1, date) {
			this.reset();
			this.dirType = type || 1;
			this.title = 'add visit';
			this.visitDate = moment().diff(date, 'days') <= 1 ? date : moment().format('YYYY-MM-DD');
			this.dialog = true;
		},

		editVisit(id) {
			this.reset();
			this.id = id;
			this.fillData();
			this.title = 'edit visit';
			this.dialog = true;
		},

		fillData() {
			const visit = this.getVisitById(this.id);
			this.dirType = visit.entity.entityType;

			this.visitDate = moment(visit.createdAt).format('YYYY-MM-DD');
			this.regionId = visit.entityAddresses[0].regionId;
			this.entityId = visit.entity.id;
			this.coreMessage = visit.coreMessage;
			this.durationInMinutes = visit.durationInMinutes;
			this.otherSamples = visit.otherSamples;
			this.visitNotes = visit.notes;
			this.objection = visit.objections;
			this.nextCall = visit.purpose;
			this.centerNotes = visit.nextVisitNotes;
			this.personsNames =
				visit.residentDoctorNames.split(',').map((c, i) => ({ id: i, name: c.trim() }));
            
			visit.unavailableMaterials.forEach(materialName => {
				this.unavailableMaterialIds.push(this.getMaterialByName(materialName).id);
			})

			this.samples = [];
			visit.materialAmounts.forEach(materialAmount => {
				this.samples.push({
					id: ++this.sampleCounter,
					data: {
						materialId: materialAmount.materialId,
						amount: materialAmount.amount
					}
				})
			});

			visit.competitiveSamples.forEach(sample => {
				this.competitors.push({
					id: this.competitorCounter++,
					value: null
				})
			});

			this.fetchEntities();
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.loading = true;

				// grouping duplicated records
				const samples = this.samples.map(({ data }) => ({
					materialId: data.materialId,
					amount: data.amount
				})).reduce((res, a) => {
					const index = res.findIndex(c => a.materialId === c.materialId);
					if (index === -1) res.push(a);
					else res[index].amount += a.amount;
					return res;
				}, [])

				// create visit
				if (!this.id) {
					this.$store.dispatch('visits/create', {
						entityId: this.entityId,
						coreMessage: this.coreMessage,
						durationInMinutes: this.durationInMinutes,
						otherSamples: this.otherSamples,
						notes: this.visitNotes,
						objections: this.objection,
						nextVisitNotes: this.centerNotes,
						purpose: this.nextCall,
						materialAmounts: samples,
						unavailableMaterialIds: this.unavailableMaterialIds,
						competitiveSamples: this.competitors.map(c => c.value),
						residentDoctorNames: this.personsNames.map(c => c.name).join(', '),
						createdAt: this.visitDate
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'Visit is created');
							this.$emit('on-save');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				} else {
					// edit visit
					this.$store.dispatch('visits/update', {
						id: this.id,
						entityId: this.entityId,
						coreMessage: this.coreMessage,
						durationInMinutes: this.durationInMinutes,
						otherSamples: this.otherSamples,
						notes: this.visitNotes,
						objections: this.objection,
						nextVisitNotes: this.centerNotes,
						purpose: this.nextCall,
						materialAmounts: samples,
						unavailableMaterialIds: this.unavailableMaterialIds,
						competitiveSamples: this.competitors.map(c => c.value),
						residentDoctorNames: this.personsNames.map(c => c.name).join(', ')
					})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', 'Visit is edited');
							this.$emit('on-save');
							this.dialog = false;
						})
						.finally(() => {
							this.loading = false;
						})
				}
			}
		}
	},

	computed: {
		...mapState({
			regions: state => state.regions.regions,
			allMaterials: state => state.materials.materials,
			transactions: state => state.materialsTransactions.transactions,
			materialForms: state => state.materialForms.materialForms,
			entities: state => state.entities.entities,
		}),

		...mapGetters({
			getVisitById: 'visits/getVisitById',
			getMaterialById: 'materials/getMaterialById',
			getMaterialByName: 'materials/getMaterialByName',
			getMaterialFormById: 'materialForms/getMaterialFormById'
		}),

		materials() {
			const availableMaterials = [];
			const availableMaterialsIds = this.transactions.map(c => c.materialId);
			availableMaterialsIds.forEach(id => availableMaterials.push(this.getMaterialById(id)));
			return availableMaterials;
		}
	}
}
</script>

<style>

</style>