<template>
    <v-bottom-sheet v-model="sheet" content-class="rounded-t-lg">
        <!-- card -->
        <v-card class="rounded-t-lg">
            <!-- title -->
            <v-toolbar dark flat color="primary" class="mb-5 text-h6 text-uppercase">
                <v-spacer/>
                {{'Vsit Details'}}
                <v-spacer/>
            </v-toolbar>

            <v-card-text>
                <!-- persons -->
                <div v-if="visit && visit.residentDoctorNames">
                    <div>
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-account</v-icon>
                        <span class="text-capitalize primary--text text--darken-1">persons</span>
                    </div>
                    <div>{{ visit.residentDoctorNames }}</div>
                </div>

                <!-- samples -->
                <div v-if="visit">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-pill</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">samples</span>
                    </div>
                    <div
                        v-if="visit.materialAmounts.length > 0"
                        class="text-body-1"
                        :class="{'text-center': $vuetify.breakpoint.smAndUp}"
                    >
                        {{ samples }}
                    </div>
                    <div v-else>No samples </div>
                </div>

                <!-- Unavailable Products -->
                <div v-if="visit && visit.unavailableMaterials.length > 0" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-close</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">Unavailable</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ unavailableMaterials }}
                    </div>
                </div>

                <!-- Competitors -->
                <div v-if="visit && visit.competitiveSamples.length > 0" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-trending-up</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">Competitors</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.competitiveSamples.join(', ') }}
                    </div>
                </div>

                <!-- other Materials table -->
                <div v-if="visit && visit.otherSamples" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-plus-minus-box</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">other Materials</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.otherSamples }}
                    </div>
                </div>

                <!-- core message -->
                <div v-if="visit && visit.coreMessage" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-outline</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">core message</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.coreMessage }}
                    </div>
                </div>

                <!-- duration -->
                <div v-if="visit && visit.durationInMinutes" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-clock-outline</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">duration</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.durationInMinutes }} min
                    </div>
                </div>

                <!-- notes -->
                <div v-if="visit && visit.notes" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-multiple-outline</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">notes</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.notes }}
                    </div>
                </div>

                <!-- Objections -->
                <div v-if="visit && visit.objections" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-alert-circle-outline</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">Objections</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.objections }}
                    </div>
                </div>

                <!-- next call -->
                <div v-if="visit && visit.purpose" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-check</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">Next Call</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.purpose }}
                    </div>
                </div>

                <!-- center notes -->
                <div v-if="visit && visit.nextVisitNotes" class="mt-1">
                    <div class="d-flex align-center" :class="{'justify-center': $vuetify.breakpoint.smAndUp}">
                        <v-icon small class="primary--text text--lighten-2 me-1">mdi-note-multiple-outline</v-icon>
                        <span class="text-capitalize primary--text text--darken-1 text-body-1">Center Notes</span>
                    </div>
                    <div class="text-body-1" :class="{'text-center': $vuetify.breakpoint.smAndUp}">
                        {{ visit.nextVisitNotes }}
                    </div>
                </div>
            </v-card-text>
            
            <v-card-actions class="pt-0">
                <v-spacer/>
                <v-btn
                    text
                    block
                    @click="sheet = false"
                    color="grey darken-2"
                >
                    DONE
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			sheet: false,
			visit: null
		}
	},

	methods: {
		getFullMaterialName(material) {
			const details = [
				this.getMaterialFormById(material.materialFormId).name,
				material.dose,
				material.strength
			];

			return `${material.name} (${details.filter(c => c != null).join(' ')})`;
		},

		activeSheet(id) {
			this.visit = this.getVisitById(id);
			this.sheet = true;
		}
	},

	computed: {
		samples() {
			const samples = [];
			this.visit.materialAmounts.forEach(materialAmmount => {
				const material = this.getMaterialById(materialAmmount.materialId);
				const amount = materialAmmount.amount;
				samples.push(this.getFullMaterialName(material) + ' (×' + amount + ')');
			});
			return samples.join(', ');
		},

		unavailableMaterials() {
			const materials = [];
			this.visit.unavailableRamaProductsCount.forEach(materialName => {
				const material = this.getMaterialByName(materialName);
				materials.push(this.getFullMaterialName(material));
			});
			return materials.join(', ');
		},

		...mapGetters({
			getVisitById: 'visits/getVisitById',
			getMaterialById: 'materials/getMaterialById',
			getMaterialByName: 'materials/getMaterialByName',
			getMaterialFormById: 'materialForms/getMaterialFormById'
		})
	}
}
</script>

<style>

</style>